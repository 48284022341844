import Head from 'next/head'
import { useRouter } from 'next/router'

interface IMeta {
  property: string
  content: string
}

interface Props {
  title: string
  meta?: IMeta[]
  canonical: string
  jsonSchema?: object[]
  noIndex?: boolean
}

const BASE_URL = 'https://www.peppercontent.io'

const Seo = ({
  title,
  meta = [],
  canonical,
  jsonSchema,
  // add no-index meta tag if noIndex is true
  noIndex = false
}: Props) => {
  const router = useRouter()
  const hasOgImage = meta.find(m => m.property === 'og:image')
  const fullCanonicalUrl = canonical.startsWith(BASE_URL)
    ? canonical
    : `${BASE_URL}${canonical}`

  return (
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="description"
        content={meta?.find(m => m.property === 'description')?.content ?? ''}
      />
      {hasOgImage && <meta name="twitter:card" content="summary_large_image" />}
      {meta?.map(m => (
        <meta key={m.property} property={m.property} content={m.content} />
      ))}

      {noIndex && <meta name="robots" content="noindex" />}

      <link rel="canonical" href={fullCanonicalUrl} />

      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${BASE_URL}${router.asPath}`}
      />

      {jsonSchema?.map((item: any) => {
        return (
          <script
            type="application/ld+json"
            key={item['@type']}
            dangerouslySetInnerHTML={{ __html: JSON.stringify(item) }}
          />
        )
      })}
    </Head>
  )
}

export default Seo
